import React from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>I like to build mobile things with React Native. I talk about it here.</>
  )
}
